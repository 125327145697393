interface FetcherOptions {
  debug?: boolean
}
interface FetcherRequestOptions {
  callbackData?: any
  customEvent?: string
}

type FetcherError = {
  error?: string
}
type FetcherResponse<T> = T | FetcherError

export function isResponseError<T>(response: FetcherResponse<T>): response is FetcherError {
  return typeof (response as FetcherError).error == 'string'
}

export default class Fetcher<T> {
  loading: boolean
  controller: AbortController
  signal: AbortSignal
  options: FetcherOptions
  url: string

  constructor(options?: FetcherOptions) {
    this.options = options || {}
  }

  /*
   * This fetch call is an extension of original fetch which only fires
   * api with a loading state before and after the call.
   * Can also debounce greater or less than 500ms if required.
   * The fetch request returns the JSON promise and is abortable
   */
  public async fetch(
    url: string,
    data: RequestInit,
    requestOptions: FetcherRequestOptions
  ): Promise<FetcherResponse<T>> {
    let response: Response
    this.url = url
    data = data || {}
    requestOptions = requestOptions || {}
    this.controller = new AbortController()
    this.signal = this.controller.signal
    data.signal = this.signal

    this.setLoading(true, requestOptions.callbackData, requestOptions.customEvent)
    try {
      response = await fetch(url, data)
      this.setLoading(false, requestOptions.callbackData, requestOptions.customEvent)
      if (this.options.debug) {
        console.log('[Fetch Request Completed]', response)
      }
      return (await response.json()) as Promise<FetcherResponse<T>>
    } catch (error) {
      if (this.options.debug) {
        console.log('[Error During Fetch]', error)
      }
      this.setLoading(false, error, requestOptions.customEvent)
      if (error.code != error.ABORT_ERR) {
        throw error
      }
    }
  }

  public abort(): void {
    if (this.options.debug) {
      console.log('[Aborting Request]', this.url)
    }
    this.controller.abort()
  }

  public setLoading(isLoading: boolean, details: Record<string, unknown>, customName: string): void {
    let loadingEvent: CustomEvent
    const startEvent = (customName && customName + 'Started') || 'CFFetchStarted'
    const endEvent = (customName && customName + 'Finished') || 'CFFetchFinished'

    if (isLoading && !this.loading) {
      if (this.options.debug) {
        console.log('[Loading Started]', startEvent)
      }
      this.loading = true
      loadingEvent = new CustomEvent(startEvent, {
        detail: details,
      })
    } else if (!isLoading && this.loading) {
      if (this.options.debug) {
        console.log('[Loading Finished/Aborted]', endEvent)
      }

      this.loading = false
      loadingEvent = new CustomEvent(endEvent, {
        detail: details,
      })
    }

    if (loadingEvent) {
      document.dispatchEvent(loadingEvent)
    }
  }
}

globalThis.CFFetcher = globalThis.CFFetcher || Fetcher
