import cfHoy from '@clickfunnels2/cfhoy.js'

let livePage = isLivePage(window.location.search)
let trackingDisabled = window.disableTracking

// ahoy
if (!trackingDisabled && livePage) {
  // we need to configure ahoy before DOMContentLoaded event
  // so it can track the visit correctly
  cfHoy.configure({
    urlPrefix: "/_tracking",
    visitsUrl: '/visits',
    eventsUrl: '/events',
    platform: 'Web',
    useBeacon: true,
    startOnReady: true,
    trackVisits: true,
    cookies: true,
    withCredentials: false,
    visitDuration: 4 * 60, // 4 hours
    visitorDuration: 2 * 365 * 24 * 60, // 2 years
  })

  document.addEventListener('DOMContentLoaded', function (event) {
    cfHoy.trackClicks("a, button, input[type=submit]");
    cfHoy.trackSubmits("form");
    cfHoy.trackChanges("input, textarea, select");
    cfHoy.trackView({ cfhoy_visitor: $.cookie('cfhoy_visitor') || '' })

    $(document).on('click', '[event-tracking="enable"]', function () {
      const data = {
        url: window.location.href,
        page: window.location.pathname,
        title: document.title,
      }

      cfHoy.track('click-custom-button', data)
    })
  })
}

function isLivePage(queryString) {
  const urlParams = new URLSearchParams(queryString)
  return urlParams.get('preview') !== 'true'
}
